<template>
    <div>
        <div class="d-flex justify-space-between align-center pb-5">
            <p style="font-size: 18px;"
               class="font-weight-semibold text-black">
                 {{ $t('Users Mangement') }}
            </p>

            <v-btn v-if="role != 'sub_admin'"
                   color="primary"
                   @click="addCaptain"
                   class="me-3 text-lower captain-info-btn">
                <span>{{ $t('Add Captain') }}</span>
            </v-btn>
        </div>
        <v-divider></v-divider>
        <v-card id="user-list"
                class="mt-6">
            <!-- search -->
            <v-card-text class="d-flex justify-space-between align-center flex-wrap pb-0">
                <div class="d-flex align-center pb-5">
                    <span class="font-weight-semibold text-base text-green text-color">{{ $t('Boat Captain Listings') }}</span>
                    <!-- </div> -->
                </div>

                <div class="d-flex align-center pb-5">
                    <v-select :items="statusOptions"
                              single-line
                              dense
                              outlined
                              hide-details
                              v-model="searchQuery"
                              label="Status"
                              @change="onChangeFilter()"
                              item-value="id"
                              item-text="name"
                              class="user-list-search me-3"></v-select>

                    <v-text-field v-model="searchText"
                                  single-line
                                  dense
                                  outlined
                                  prepend-inner-icon="mdi-magnify"
                                  hide-details
                                  placeholder="Search"
                                  @input="onSearch()"
                                  width="33px"
                                  class="user-search me-3"></v-text-field>
                </div>
            </v-card-text>

            <!-- table -->
            <v-data-table v-model="selectedTableData"
                          :headers="tableColumns"
                          :items="userListTable"
                          :footer-props="{ itemsPerPageOptions: [5, 10] }"
                          :options.sync="options"
                          :server-items-length="totalUserListTable"
                          :loading="loading"
                          :class="{ 'light-mode': shouldApplyClass, 'dark-mode': !shouldApplyClass }"
                          class="text-no-wrap my-data-table">
                <!-- trending header -->
                <template #[`header.trending`]>
                    <v-icon size="22">
                        {{ icons.mdiTrendingUp }}
                    </v-icon>
                </template>

                <!-- id -->
                <template #[`item.id`]="{ item }">
                    <div class="text-black font-size">{{ item.id }}</div>

                </template>
                <template v-slot:item.first_name="{ item }">
                    <div class="text-black font-size">{{ item.first_name }}</div>
                </template>
                <template v-slot:item.email="{ item }">
                    <div class="text-black font-size">{{ item.email }}</div>
                </template>
                <template v-slot:item.phone="{ item }">
                    <div class="text-black font-size">{{ item.phone }}</div>
                </template>
                <template v-slot:item.created_at="{ item }">
                    <div class="text-black font-size">{{ item.created_at }}</div>
                </template>
                

                <!-- actions -->
                <template #[`item.actions`]="{ item }">
                    <div class="d-flex align-center justify-center">
                        <!-- view -->
                        <div v-if="role != 'sub_admin'">
                            <template bottom>
                                <v-switch v-model="item.status"
                                          inset
                                          @change="updateUserStatus(item.status, item.id)"
                                          value="1">
                                </v-switch>
                            </template>
                        </div>

                        <div v-if="role != 'sub_admin'">
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon
                                           small
                                           v-bind="attrs"
                                           @click="updateCaptain(item)"
                                           color="#00B2A9"
                                           v-on="on">
                                        <v-icon size="18"
                                                class="text-green">
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('Edit') }}</span>
                            </v-tooltip>
                        </div>

                        <v-tooltip bottom>

                            <template #activator="{ on, attrs }">
                                <v-btn icon
                                       small
                                       v-bind="attrs"
                                       @click="viewCaptain(item)"
                                       v-on="on">
                                    <v-icon size="20"
                                            color="#00B2A9"
                                            class="text-green">
                                        {{ icons.mdiEyeOutline }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('View') }}</span>
                        </v-tooltip>


                    </div>
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
import userServices from '@/services/user-services';
import { mdiEyeOutline } from '@mdi/js';

import darkMode from '@core/utils/modalColor';
// composition function
import router from '@/router';
import BoatCaptainList from './BoatCaptainList';
import store from '@/store';
export default {
    data: () => ({
        applicationList: '',
        role: 'admin',
        shouldApplyClass: true,
        statusOptions: [
            {
                id: '',
                name: 'All'
            },
            {
                id: 1,
                name: 'Active'
            },
            {
                id: 2,
                name: 'InActive'
            }]
    }),
    created()
    {
        store.subscribe((mutation) =>
        {
            if (mutation.type === 'accounts/SET_DARK_MODE')
            {
                this.shouldApplyClass = mutation.payload;
            }
        })
    },
    mounted()
    {
        let isDark = localStorage.getItem('my-active-theme');
        if (isDark == 'dark')
        {
            this.shouldApplyClass = false;
        }
        let currentRole = JSON.parse(localStorage.getItem('userData'));
        this.role = currentRole.role;
    },

    methods: {
        addCaptain()
        {
            router.push('/add/captain');
        },
        updateCaptain(item)
        {
            router.push(`/update/captain/${item.id}`);
        },
        viewCaptain(item)
        {
            router.push(`/captain/${item.id}`);
        },
        updateUserStatus(status, id)
        {

            let url;
            let body;
            if (status == '1')
            {
                url = 'users/accept'
                body = {
                    "id": id,
                    "status": "1"
                }
            }
            else
            {
                url = 'users/reject'
                body = {
                    "id": id,
                    "status": "2"
                }
            }
            userServices.updateStatus(url, body).then(resp =>
            {
                if (resp.error)
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        text: 'Something went wrong!',
                    })
                }
                else
                {
                    let status = resp.data.user.status == '1' ? 'Boat captain has been activated successfully' : 'Boat Captain has been deactivated successfully';
                    this.$swal({
                        text: `${status}`,
                        confirmButtonColor: darkMode.getTextColor(),
                        customClass: darkMode.getBackgroundColor(),
                        icon: 'success',
                    }).then(result =>
                    {

                        // refresh here
                    })
                }
            })
        }
    },
    setup()
    {
        const {
            userListTable,
            searchQuery,
            searchText,
            tableColumns,
            options,
            totalUserListTable,
            loading,
            selectedTableData,
            resolveRole,
            onChangeFilter,
            onSearch
        } = BoatCaptainList()

        return {
            tableColumns,
            searchQuery,
            searchText,
            options,
            totalUserListTable,
            userListTable,
            loading,
            selectedTableData,
            resolveRole,
            onChangeFilter,
            onSearch,

            icons: {
                mdiEyeOutline
            },
        }
    },
}
</script>

<style lang="scss" scoped>
#user-list {
    .user-list-actions {
        max-width: 7.81rem;
    }

    .user-list-search {
        max-width: 10.625rem;
        border-radius: 20px;
    }

    .user-list-status {
        max-width: 11.3rem;
    }
}

// ::v-deep .v-input--selection-controls {
//     margin-top: 0px;
//     padding-top: 4px !important;
// }

.user-search {
    max-width: 17.625rem;
    border-radius: 20px;
}

.text-color {
    color: #00B2A9;
}

.font-size {
    font-size: 14px;
    font-weight: 400;
}

.my-data-table ::v-deep .v-data-table-header th {
    font-weight: bold;
    font-size: 14px;
    height: 40px;
}


// ::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
// .v-data-table>.v-data-table__wrapper>table>thead>tr>td,
// .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td {
//     font-size: 0.875rem;
//     height: 3.125rem;
//     border-left: 1px solid #efeded !important;
// }

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
    color: #131C24 !important;
    background: rgba(165, 174, 182, 0.15) !important;

}

::v-deep .theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row),
.theme--dark.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row) {
    border-bottom: none !important;
}

// ::v-deep .v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
//     border-bottom: thin solid rgba(94, 86, 105, 0.14);
//     border-right: 1px solid #efeded;
// }
</style>
